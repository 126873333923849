import React from 'react';
import cx from 'classnames';
import { defaultConfigToApply } from '../.././../../utils/randomPhoto';
import { nameInitials } from '../../../../utils/functions';
import style from './StoreLogo.module.css';

interface Data {
  url: string;
  title: string;
}

interface Props {
  data: Data;
  name: string;
}

export const StoreLogo = ({ data: { url, title }, name }: Props) => {
  const logoStyle = cx(
    'gtmGoToStore absolute br-100 ba b--gray contain bg-center bg-white',
    style.logoSettings,
  );
  const storeStyle = url
    ? { backgroundImage: `url(${url})` }
    : { backgroundColor: defaultConfigToApply.bgColor };

  const initialsStore = nameInitials(name);
  const initials = !url && (
    <p
      data-value={name}
      className="gtmGoToStore br-100 flex justify-center w-100 h-100 items-center f44 abbey fw1 ma0 ttu"
    >
      {initialsStore.length > 2 ? initialsStore.substr(0, 1) : initialsStore}
    </p>
  );

  return (
    <div data-value={name} className={logoStyle} style={{ ...storeStyle }} title={title}>
      {initials}
    </div>
  );
};
