import { useState, useEffect } from 'react';
import concat from 'lodash/concat';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import inRange from 'lodash/inRange';
import map from 'lodash/map';
import { SearchShops } from '../services/search';

interface Props {
  name: string;
  page: number;
  city: string;
  uf: string;
  previousDataShop: [];
  allShops: [];
  getShop?: boolean;
}

const hasMore = (data: [], totalShop: number) => {
  if (!!data && inRange(data.length, 1, totalShop * 1)) {
    return true;
  }

  return false;
};

const hasNoData = (data: []) => {
  if (!data || (data && !data.length)) {
    return false;
  }

  return true;
};

const isFirstPage = (page: number) => page === 0;

export function useStores({
  name,
  page = 0,
  city,
  uf,
  previousDataShop,
  allShops,
  getShop = true,
}: Props) {
  const [store, setStore] = useState({
    dataShop: [],
    totalShop: 0,
    hasMore: false,
    noData: true,
    dataCount: 0,
    availableFilters: null,
    allShops: [],
  });

  useEffect(() => {
    const getDataShop = async () => {
      name = name.replace(/%/g, '\\%');
      const response = await SearchShops.getSearchShops(encodeURI(name), page, encodeURI(city), uf)
        .then(res => {
          return res;
        })
        .catch(err => {
          return err;
        });

      const responseData = get(response, 'data', []);

      const storesObj: any = {};
      storesObj.allShops = allShops;

      if (!allShops || !allShops.length || getShop) {
        storesObj.allShops = await SearchShops.getAllShops(city, uf)
          .then(serviceResponse => {
            return get(serviceResponse, 'data', []);
          })
          .catch(err => {
            return err;
          });
      }

      const cities = groupBy(storesObj.allShops, item => [item.city, item.state]);

      const newCities = map(cities, (_item, key) => {
        const newKey = key.split(',');
        return `${newKey[0]}-${newKey[1]}`;
      });

      if (response.status === 200) {
        storesObj.dataShop = concat(isFirstPage(page) ? [] : previousDataShop, responseData);
      } else {
        storesObj.dataShop = null;
      }

      storesObj.totalShop = response.headers['content-range'] || 0;
      storesObj.hasMore = hasMore(storesObj.dataShop, storesObj.totalShop);
      storesObj.noData = hasNoData(storesObj.dataShop);
      storesObj.dataCount = store.dataCount + 1;
      storesObj.availableFilters = newCities;

      setStore(storesObj);
    };

    if (!getShop) {
      getDataShop();
    }
  }, [name, page, city, getShop]);

  return store;
}
