import React from 'react';
import { Button } from '../Button';
import styles from './style.module.css';

interface Props {
  onSearchAgain: () => void;
  textInfo: string;
  imgEmpty: string;
  btnText: string;
}

export const EmptyState = ({ onSearchAgain, textInfo, imgEmpty, btnText }: Props) => {
  return (
    <section className="w-100 flex flex-column items-center pb16-ns pb8-m pb16-l mb48 mb72-m pb8-l mt32">
      <img
        src={`${process.env.FTP_IMAGENS}${imgEmpty}`}
        className={styles.imgEmptyState}
        alt="Ilustração para busca sem resultados."
      />
      <div className="mt12 mt32-ns pt4-m pt12-l tc">
        <h1 className="fw7-ns f26 f30-ns f36-m mt0 mb12 mb0-ns mb16-m mb16-l pt0-l trueno abbey">
          Que pena!
        </h1>
        <h2 className="fw2 f16 f20-ns f26-m mb0 mt0 lh-paragraph-m lh-paragraph-l abbey trueno">
          {textInfo}
        </h2>
      </div>
      <div className="flex justify-center mt16 mt12-ns mt16-m mt16-l">
        <Button classButton={styles.buttonSearchAgain} onClick={onSearchAgain}>
          {btnText}
        </Button>
      </div>
    </section>
  );
};

EmptyState.defaultProps = {
  btnText: 'buscar novamente',
};
