import { axiosInstance } from '../../../services/axios-instance';

export const SearchShops = {
  getSearchShops: (name?: string, page?: number, city?: string, state?: string) =>
    axiosInstance
      .get(`/user/find/store?name=${name}&type=2&page=${page}&city=${city}&state=${state}&take=12`)
      .then(res => {
        return res;
      })
      .catch(err => err),

  getAllShops: (city?: string, state?: string) =>
    axiosInstance.get(`/user/find/store?city=${city}&state=${state}`).catch(err => err),
};
