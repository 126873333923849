import React from 'react';
import cx from 'classnames';
import styles from '../../../style.module.css';

interface Props {
  children: React.ReactNode;
}
export const WrapperFilters = ({ children }: Props) => {
  const asideClass = cx('pr16 pt72 w-100 h-100 overflow-y-auto', styles.asideClass);
  return <aside className={asideClass}>{children}</aside>;
};
