import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import difference from 'lodash/difference';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import QueryString from 'query-string';
import Sidebar from 'react-sidebar';
import { unCheckedAllCheckBox } from '../../utils/functions';
import { FloatFilter } from './components/ActionFilters';
import { AsideFilters } from './components/AsideFilters';
import { MainContentFilter } from './components/MainContentFilter';
import { useStores } from './hooks/useStores';
import styles from './style.module.css';

const listener = {
  matches: true,
  addListener: () => null,
  removeListener: () => null,
};

const mql = typeof window !== 'undefined' ? window.matchMedia('(min-width: 1024px)') : listener;

export const ResultFilterShop = () => {
  const [itemsFilter, setItemsFilter] = useState<any>([]);
  const [toggleFilters, setToggleFilter] = useState(true);
  const [sidebarDocked, setSidebarDocked] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasLoading, setHasLoading] = useState(true);
  const [previousDataShop, setPreviousDataShop] = useState<any>([]);
  const [city, setCity] = useState('');
  const [page, setPage] = useState(0);
  const [uf, setUf] = useState('');
  const valueRef = useRef<any>('');
  const [selected, setSelected] = useState<any>();
  const [allShops, setAllShops] = useState<any>();

  const [getShop, setGetShop] = useState(true);

  const useStore = useStores({
    name: valueRef.current.value || '',
    page,
    city,
    uf,
    previousDataShop,
    allShops,
    getShop,
  });

  const handleGetParamsFromUrlAndSearch = () => {
    const search = typeof window !== 'undefined' ? window.location.search : '';
    const queryParams = QueryString.parse(search);
    handleSetFilterList(queryParams);
  };

  useEffect(() => {
    mediaQueryChanged();
    handleGetParamsFromUrlAndSearch();
  }, []);

  const handleSetFilterList = (queryParams?: any) => {
    setHasLoading(true);
    const queryCity = get(queryParams, 'cidade', '');
    const querySearch = get(queryParams, 'nome', '');

    if (queryCity) {
      const citySelected = queryCity.split('-');
      if (citySelected.length) {
        setSelected({
          city: { eq: citySelected[0] },
          state: { eq: citySelected[1] },
        });
        setCity(citySelected[0]);
        setUf(citySelected[1]);
      }
    }

    if (querySearch) {
      valueRef.current.value = querySearch;
    }
    setGetShop(false);
  };

  useEffect(() => {
    if (
      !useStore.dataShop ||
      !useStore.dataShop.length ||
      (useStore.dataShop !== null && useStore.dataShop.length)
    ) {
      setPreviousDataShop(useStore.dataShop);
      removeLoading();
    }
    if (useStore.allShops && useStore.allShops.length) {
      setAllShops(useStore.allShops);
    }
  }, [useStore.dataShop]);

  useEffect(() => {
    updateItemsFilter();
  }, [city, valueRef.current.value]);

  const removeLoading = () => {
    if (!getShop) {
      setHasLoading(false);
      setIsLoadingMore(false);
      if (!mql.matches) {
        setSidebarDocked(false);
        setToggleFilter(false);
      }
    }
  };

  const mediaQueryChanged = () => {
    if (mql.matches) {
      setSidebarDocked(true);
      setToggleFilter(true);
    } else {
      setSidebarDocked(false);
      setToggleFilter(false);
    }
  };

  const onSearchClick = () => {
    setHasLoading(true);
    resetPage();
    setPreviousDataShop([]);
    updateItemsFilter('search');
  };

  const useNextPage = () => setPage(page + 1);

  const resetPage = () => setPage(0);

  const onClickMore = () => {
    setIsLoadingMore(true);
    useNextPage();
  };

  const onToggleFilter = () => {
    setToggleFilter(!toggleFilters);
    if (mql.matches) {
      setSidebarDocked(!sidebarDocked);
    } else {
      setSidebarDocked(false);
    }
  };

  const onRemoveChips = (items: any) => {
    const arrayToCompare = [itemsFilter[items]];
    onRemoveItems(arrayToCompare);
  };

  const onRemoveItems = (specificItem: any) => {
    setHasLoading(true);

    const diff = difference(itemsFilter, specificItem);
    const itemToDelete = get(specificItem, '0', null);

    switch (get(itemToDelete, 'type', '')) {
      case 'nome':
        valueRef.current.value = '';
        break;
      case 'city':
        setCity('');
        setUf('');
        setSelected(null);
        break;
    }

    if (!diff || !diff.length) {
      window.history.replaceState(null, '', '/lojas');
    }

    setItemsFilter(diff);
  };

  const updateItemsFilter = (type?: string) => {
    const newItemsFilter = [];

    if (type === 'search') {
      if (!valueRef.current.value) {
        setHasLoading(false);
        return null;
      }
    }

    if (!!valueRef.current.value) {
      const obj = { eq: valueRef.current.value, type: 'nome' };
      newItemsFilter.push(obj);
    }

    if (!!city) {
      const obj = { eq: city, type: 'city' };
      newItemsFilter.push(obj);
    }

    if (!!uf) {
      const obj = { eq: uf, type: 'state' };
      newItemsFilter.push(obj);
    }

    const newFilters = keyBy(newItemsFilter, item => item.type);

    setItemsFilter(newFilters);
  };

  const onRemoveAllItemsInFilter = () => {
    valueRef.current.value = '';
    setHasLoading(true);
    unCheckedAllCheckBox('_hasChecked');
    setItemsFilter([]);
    setCity('');
    setSelected(null);
    setUf('');
    resetPage();
    navigate('/lojas');
  };

  const onSearchAgainEmptState = () => {
    onRemoveAllItemsInFilter();
  };

  const handleStoreByCity = (_type: string, e: any) => {
    setHasLoading(true);
    const newCity = e.split('-');
    setCity(newCity[0]);
    setUf(newCity[1]);
    setSelected({
      city: { eq: newCity[0] },
      state: { eq: newCity[1] },
    });
  };

  const sidebarClass = cx('bg-white', styles.sideBarClassName);

  const renderAsideFilters = (
    <AsideFilters
      actionToggleFilters={onToggleFilter}
      onSearchClick={onSearchClick}
      valueRef={valueRef}
      hasVisible={toggleFilters}
      filters={useStore}
      handleStoreByCity={handleStoreByCity}
      selected={selected}
    />
  );

  return (
    <Sidebar
      sidebarId="sidebar"
      sidebarClassName={sidebarClass}
      sidebar={renderAsideFilters}
      open={toggleFilters}
      onSetOpen={onToggleFilter}
      docked={sidebarDocked}
      transitions
      shadow
    >
      <MainContentFilter
        asideOpen={toggleFilters}
        onSearchAgain={onSearchAgainEmptState}
        noData={useStore.noData}
        itemsChips={itemsFilter}
        numberOfProducts={useStore.totalShop}
        onRemove={onRemoveChips}
        onRemoveChekboxChecked={onRemoveAllItemsInFilter}
        dataShop={useStore.dataShop}
        onClickMore={onClickMore}
        isLoadingMore={isLoadingMore}
        hasMore={useStore.hasMore}
        hasLoading={hasLoading}
      />
      <FloatFilter onClick={onToggleFilter} hasVisible={toggleFilters} />
    </Sidebar>
  );
};
