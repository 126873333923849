import React from 'react';
import cx from 'classnames';
import styles from '../style.module.css';

interface Props {
  x: number;
  y: number;
  circleSize: number;
  rippleSlow?: boolean;
}
export const SpanRipple = ({ x, y, circleSize, rippleSlow }: Props) => {
  const rippleClass = cx(
    'absolute bg-wild-sand br-100',
    { [styles.ripple]: !rippleSlow },
    { [styles.rippleSlow]: rippleSlow },
  );
  const rippleStyle = {
    top: y,
    left: x,
    width: circleSize,
    height: circleSize,
  };
  return <span className={rippleClass} style={rippleStyle} />;
};
