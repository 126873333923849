import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../../../components/Icons';
import styles from '../style.module.css';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hasVisible: boolean;
}
export const ActionFilterInSideBar = ({ onClick, hasVisible }: Props) => {
  if (!hasVisible) {
    return null;
  }
  const btnClass = cx(
    'pointer flex items-center justify-center absolute right-0 br3 gradient-torch-red white w2 h2 bn z-2',
    styles.actionFilterInSideBar,
  );
  return (
    <button className={btnClass} onClick={onClick}>
      <div className="flex items-center justify-center">
        <Icon name="SvgIconArrowRightBold" className="rotate-180" width={16} height={16} />
      </div>
    </button>
  );
};
