import React from 'react';
import cx from 'classnames';
import { LoaderCard } from '../../../../components/Loaders';
import { Footer } from '../../../Footer';
import { SectionBanner } from '../../../Home/components/SectionBanner';
import { CardsOfShop } from './components/CardsOfShop';
import { LoadingMoreShop } from './components/LoadingMoreShop';
import { SectionSearchWithChips } from './components/SectionSearchWithChips';
import styles from './style.module.css';

interface Props {
  itemsChips: any;
  dataShop: any;
  noData: boolean;
  asideOpen: boolean;
  hasMore: boolean;
  hasLoading: boolean;
  isLoadingMore: boolean;
  numberOfProducts: number;
  onRemove: (value: string) => void;
  onSearchAgain: () => void;
  onRemoveChekboxChecked: () => void;
  onClickMore: () => void;
}
export const MainContentFilter = ({
  onSearchAgain,
  noData,
  itemsChips,
  numberOfProducts,
  onRemove,
  onRemoveChekboxChecked,
  dataShop,
  asideOpen,
  onClickMore,
  hasMore,
  hasLoading,
  isLoadingMore,
}: Props) => {
  const renderLoadingMoreShop = () => {
    if (hasLoading || !noData) {
      return null;
    }
    return (
      <LoadingMoreShop
        onClick={onClickMore}
        hasMore={hasMore}
        isLoading={isLoadingMore}
        className="ph12"
        classNameBtn="mb8"
      />
    );
  };

  const renderCards = () => {
    if (hasLoading) {
      return <LoaderCard />;
    }

    return (
      <>
        <CardsOfShop
          data={dataShop}
          isOpen={asideOpen}
          hasLoading={hasLoading}
          noData={noData}
          onSearchAgain={onSearchAgain}
        />
      </>
    );
  };

  const renderChips = () => {
    if (!hasLoading) {
      return (
        <SectionSearchWithChips
          items={itemsChips}
          numberOfProducts={numberOfProducts}
          onRemove={onRemove}
          onRemoveChekboxChecked={onRemoveChekboxChecked}
        />
      );
    }
  };

  const container = cx(styles.mc, { [styles.mcOpen]: asideOpen });

  return (
    <div className="bg-wild-sand h-100 overflow-x-hidden">
      <div className={cx(styles.alingStore, { [styles.ma]: !asideOpen })}>
        <section className={container}>
          <section className="flex flex-column-reverse flex-column-ns pt72">
            <SectionBanner
              position="pageInitialOne"
              className="db mb0 pt24 pb24 pb32-ns pt16-ns pt32-m"
            />
            {renderChips()}
          </section>
        </section>
        <section className={container}>
          <div className={cx(styles.gridCss)}>{renderCards()}</div>
          {renderLoadingMoreShop()}
        </section>
      </div>
      <Footer />
    </div>
  );
};
