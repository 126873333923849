import React from 'react';
import cx from 'classnames';
import { defaultConfigToApply } from '../.././../../utils/randomPhoto';
import style from './StoreCover.module.css';

interface Props {
  url: string;
  dataValue?: string;
}

export const StoreCover = ({ url, dataValue }: Props) => {
  const coverImageStyle = cx(
    'gtmGoToStoreHome w-100 ma0 cover bg-center',
    style.coverPictureSizing,
  );
  const backgroundImage = `url(${url || defaultConfigToApply.bgImage})`;
  return <div className={coverImageStyle} style={{ backgroundImage }} data-value={dataValue} />;
};
