import React from 'react';
import cx from 'classnames';
import { Icon } from '../../Icons';
import styles from '../style.module.css';

interface Props {
  value: string | number;
  focus: any;
  onClick: any;
}

export const ActionSearch = ({ value, focus, onClick }: Props) => {
  const buttonClass = cx('bn flex justify-center items-center pointer', styles.buttonClass, {
    [styles.buttonWhenInputFocus]: focus || value,
    white: focus || value,
    abbey: !focus && !value,
    'bg-alto': !focus,
  });
  return (
    <button className={buttonClass} onClick={onClick}>
      <Icon name="SvgIconSearch" />
    </button>
  );
};
