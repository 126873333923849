import { toFloat } from './functions';

export const convertStringToNumber = (value: string) => {
  const valueConvert = Number(value);
  return valueConvert;
};

export const convertNumberToString = (value: number | string) => {
  const valueConvert = String(value);
  return valueConvert;
};

export const formatCurrency = (currency: any | number, minimumFractionDigits?: number) => {
  const willReturn = typeof currency === 'string' ? toFloat(currency) : currency;

  const formatter = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits:
      minimumFractionDigits || minimumFractionDigits === 0 ? minimumFractionDigits : 2,
  });

  return formatter.format(willReturn);
};
