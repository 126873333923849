import React, { useState } from 'react';
import cx from 'classnames';
import { ActionSearch } from './components/ActionSearch';
import styles from './style.module.css';

interface Props {
  value?: string | number;
  placeholder: string;
  onClick: (e: any) => void;
  hasValue?: boolean;
  valueRef?: any;
  rest?: any;
}

export const SearchShop = ({ value = '', placeholder, onClick, valueRef, rest }: Props) => {
  const [focus, setFocus] = useState(false);
  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = () => {
    setFocus(false);
  };
  const containerSearch = cx('flex overflow-hidden br3 ba b--gray border-box', styles.h48);
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <form onSubmit={handleOnSubmit} className={containerSearch}>
      <input
        ref={valueRef}
        placeholder={placeholder}
        className="f14 fw3 w-100 bn pl12 br3 mh0-ns abbey"
        onFocus={onFocus}
        onBlur={onBlur}
        {...rest}
      />
      <ActionSearch value={value} focus={focus} onClick={onClick} />
    </form>
  );
};
