import React from 'react';
import cx from 'classnames';
import { Button } from '../../../../../../components/Button';
import styles from '../../../../style.module.css';

interface Props {
  onClick: () => void;
  hasMore: boolean;
  className?: string;
  classNameBtn?: string;
  isLoading: boolean;
}
export const LoadingMoreShop = ({
  onClick,
  hasMore,
  className,
  classNameBtn,
  isLoading,
}: Props) => {
  const btnMore = cx('w-100', styles.btnMore, [classNameBtn]);
  const container = cx('w-100 flex justify-center', styles.containerBtn, [className]);
  if (!hasMore) {
    return null;
  }
  return (
    <div className={container}>
      <div className={btnMore}>
        <Button onClick={onClick} loading={isLoading}>
          Carregar mais lojas
        </Button>
      </div>
    </div>
  );
};
