import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../../../components/Icons';
import styles from '../style.module.css';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hasVisible: boolean;
}
export const ActionFloatFilter = ({ onClick, hasVisible }: Props) => {
  if (hasVisible) {
    return null;
  }
  const btnClass = cx(
    'pointer flex items-center justify-start fixed left-0 br3 br--right gradient-torch-red white bn z-2 pa0',
    styles.actionFloatFilter,
  );
  return (
    <button className={btnClass} onClick={onClick}>
      <div className="flex items-center justify-center mh4">
        <Icon name="SvgIconSearchFilter" width={24} height={24} />
      </div>
      <div className="flex items-center justify-center">
        <Icon name="SvgIconArrowRightBold" width={16} height={16} />
      </div>
    </button>
  );
};
