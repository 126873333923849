import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _lowerCase from 'lodash/lowerCase';
import _map from 'lodash/map';
import { FilterWrapper } from '../../../components/FilterWrapper';
import { Select } from '../../../components/Select';
import { slugFormat } from '../../../utils/functions';

interface Props {
  stores: any;
}

export const SelectStore = ({ stores }: Props) => {
  const [normalizedStores, setStore] = useState([{ label: '', value: '' }]);
  const [normalizedLocalities, setNormalizedLocalities] = useState([{ label: '', value: '' }]);

  useEffect(() => {
    const normalized = _map(stores, (item: any) => {
      return {
        label: item.fantasyName || item.name,
        value: item.id.toString(),
      };
    });

    setStore(normalized);
    setNormalizedLocalities(normalized);
  }, [stores]);

  const handleOnInputChangeLocality = (value: string) => {
    const filteredLocalities = _filter(normalizedLocalities, (item: any) => {
      return _includes(_lowerCase(item.label), _lowerCase(value));
    });

    setStore(filteredLocalities);
  };

  const handleOnChangeLocality = (e: any) => {
    if (e) {
      const selected = _find(stores, (item: any) => item.id === parseInt(e.value, 10));

      const fantasyName = _get(selected, 'fantasyName', '');
      const town = _get(selected, 'city', '');
      const id = _get(selected, 'id', '');
      const state = _get(selected, 'state', '');

      const storeUrl = slugFormat(`/loja/${state}/${town}/${fantasyName}/${id}`);
      navigate(storeUrl);
    }
  };

  return (
    <form>
      <FilterWrapper title="Todas as lojas" className="mb16" titleClass="pb8">
        <Select
          locationDropdown="#sidebar-search"
          name="store"
          title="Loja"
          options={normalizedStores}
          onChange={handleOnChangeLocality}
          onInputChange={handleOnInputChangeLocality}
        />
      </FilterWrapper>
    </form>
  );
};
