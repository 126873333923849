import React from 'react';
import { SearchWithChips } from '../../../../../../components/SearchWithChips';

interface Props {
  items: any;
  numberOfProducts: number;
  onRemove: (value: string) => void;
  onRemoveChekboxChecked?: () => void;
}
export const SectionSearchWithChips = ({
  items,
  numberOfProducts,
  onRemove,
  onRemoveChekboxChecked,
}: Props) => {
  return (
    <SearchWithChips
      items={items}
      numberOfProducts={numberOfProducts}
      onRemove={onRemove}
      onRemoveChekboxChecked={onRemoveChekboxChecked}
    />
  );
};
