import sampleSize from 'lodash/sampleSize';

const picturesSource = `${process.env.FTP_IMAGENS}images/my-ads`;

const config = [
  {
    bgColor: '#F9B0B0',
    bgImage: `${picturesSource}/cover-red.jpg`,
  },
  {
    bgColor: '#b8f4b5',
    bgImage: `${picturesSource}/cover-green.jpg`,
  },
  {
    bgColor: '#d3e5ee',
    bgImage: `${picturesSource}/cover-blue.jpg`,
  },
];

export const defaultConfigToApply = sampleSize(config, 1)[0];
