import React from 'react';
import { EmptyState } from '../../../../../../components/EmptyState';

interface Props {
  hasLoading: boolean;
  onSearchAgain: () => void;
}
export const NoDataEmptyState = ({ onSearchAgain }: Props) => {
  return (
    <EmptyState
      textInfo="Não encontramos nenhuma loja."
      imgEmpty="images/search/empty-state.png"
      onSearchAgain={onSearchAgain}
    />
  );
};
