import React from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { GridCell } from '../../../../../../components/GridCell';
import { LoaderCard } from '../../../../../../components/Loaders';
import { StoreCard } from '../../../../../../components/StoreCard';
import { NoDataEmptyState } from '../NoDataEmptyState';
import styles from './style.module.css';
import { formattedDataShop } from './utils/formattedShopData';

interface Props {
  data: any;
  isOpen: boolean;
  noData: boolean;
  hasLoading: boolean;
  onSearchAgain: any;
}

export const CardsOfShop = ({ data, noData, hasLoading, onSearchAgain }: Props) => {
  if (hasLoading) {
    return <LoaderCard />;
  }

  if (!noData) {
    return <NoDataEmptyState onSearchAgain={onSearchAgain} hasLoading={hasLoading} />;
  }

  const s3 = process.env.FTP_IMAGENS;
  const newData = formattedDataShop(data, s3);

  const widthGrid = [0.9192, 0.956];
  const renderStoreCard = () => {
    return map(newData, (item, idx) => {
      const { id, imageData, coverPhoto, bestOffers, storeName, storeLocation } = item;
      return (
        <GridCell
          key={`${storeName}-${idx}`}
          width={widthGrid}
          className={cx('mb12', styles.gridCenter)}
          my={['12px']}
        >
          <StoreCard
            logo={imageData}
            cover={coverPhoto}
            offers={bestOffers}
            name={storeName}
            location={storeLocation}
            id={id}
          />
        </GridCell>
      );
    });
  };
  return <>{renderStoreCard()}</>;
};
