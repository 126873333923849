import React from 'react';
import { Layout } from '../components/Layout';
import { Menu } from '../modules/Menu';
import { ResultFilterShop } from '../modules/ResultFilterShop';

const SandBoxShopFilter = () => (
  <Layout>
    <Menu selectedItem="Vitrine" menuHasTwoColors={false} />
    <ResultFilterShop />
  </Layout>
);

export default SandBoxShopFilter;
