import React from 'react';
import { Divider } from '../../../../components/Divider';
import { SearchShop } from '../../../../components/SearchShop';
import { StoreByCity } from '../../../Filter/StoreByCity';
import { ActionFilterInSideBar } from '../ActionFilters/components/ActionFilterInSideBar';
import { SelectStore } from '../selectStore';
import { WrapperFilters } from './components/WrapperFilters';

interface Props {
  hasVisible: boolean;
  filters?: any;
  actionToggleFilters: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSearchClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent,
  ) => void;
  valueRef: any;
  handleStoreByCity: any;
  selected: any;
}
export const AsideFilters = ({
  actionToggleFilters,
  onSearchClick,
  hasVisible,
  valueRef,
  filters,
  handleStoreByCity,
  selected,
}: Props) => {
  return (
    <WrapperFilters>
      <ActionFilterInSideBar onClick={actionToggleFilters} hasVisible={hasVisible} />
      <div className="pl32 mt32">
        <SearchShop placeholder="Busque uma loja" onClick={onSearchClick} valueRef={valueRef} />
      </div>
      <div className="pr32">
        <Divider className="mv32" />
      </div>
      <div className="pl32">
        <StoreByCity
          hasCity={!!selected}
          selected={selected}
          getValues={handleStoreByCity}
          localities={filters.availableFilters}
          showStore={false}
          hasStore={false}
        />
      </div>
      <div className="pr32">
        <Divider className="mv32" />
      </div>
      <div className="pl32">
        <SelectStore stores={filters && filters.allShops} />
      </div>
    </WrapperFilters>
  );
};
