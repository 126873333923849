import React, { Component } from 'react';
import cx from 'classnames';
import hoverEffects from '../CardCategory/CardCategory.module.css';
import { Ripple } from '../Ripple';
import { StoreBestOffers } from './components/StoreBestOffers/StoreBestOffers';
import { StoreCover } from './components/StoreCover/StoreCover';
import { StoreLogo } from './components/StoreLogo/StoreLogo';
import styles from './style.module.css';
import { urlFormatted } from './utils/formattedUrl';

interface Logo {
  url: string;
  title: string;
}

interface Offer {
  id: number | string;
  url: string;
}

interface Location {
  city: string;
  state: string;
}

interface Props {
  logo: Logo;
  cover: string;
  offers: Offer[];
  name: string;
  location: Location;
  id: number;
  target?: string;
}

export class StoreCard extends Component<Props> {
  render() {
    const {
      id,
      logo,
      offers,
      name,
      cover,
      target,
      location: { city, state },
    } = this.props;
    const ellipsis = (phrase: string, where: number) => {
      return phrase.substring(0, where) + (phrase.length > where ? '...' : '');
    };
    const storeNameClass = cx(
      'gtmGoToStoreHome ma0 ttu f12 f18-l fw700 abbey lh-solid mt32 pt8 pt16-l mh8 mh16-ns w-60 w-100-l',
      styles.storeNameClass,
    );

    const newUrl = urlFormatted({ city, name, id, state });

    const wrapperStoreCard = cx('relative pointer', hoverEffects.wrapperCard);

    return (
      <div className={wrapperStoreCard}>
        <a
          href={newUrl}
          target={target}
          className="no-underline gtmGoToStoreHome"
          title={name}
          data-value={name}
        >
          <div
            className="relative w-100 trueno br10 bg-white flex flex-column items-center justify-center"
            data-value={name}
          >
            <StoreCover url={cover} dataValue={name} />
            <Ripple extendClassname="h-50 w-100" rippleSlow dataValue={name}>
              <div>
                <div
                  className="tc flex w-100 flex-column items-center justify-center"
                  data-value={name}
                >
                  <h3 className={storeNameClass} title={name} data-value={name}>
                    {ellipsis(name, 20)}
                  </h3>
                  <p
                    className="ma0 f11 f12-l boulder mt4 mb12 mb24-l mh8 mh16-ns"
                    data-value={name}
                  >
                    {city} - {state}
                  </p>
                </div>
                <div className="w-100 flex justify-center ph4 pb8 ph16-l pb16-l" data-value={name}>
                  <StoreBestOffers offers={offers} storeName={name} />
                </div>
              </div>
            </Ripple>
          </div>
          <StoreLogo data={logo} name={name} />
        </a>
      </div>
    );
  }
}
