import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import cx from 'classnames';
import map from 'lodash/map';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import style from './StoreBestOffers.module.css';

interface Offer {
  id: number | string;
  url: string;
}

interface Props {
  offers: Offer[];
  storeName: string;
}
export const StoreBestOffers = ({ offers, storeName }: Props) => {
  const offerStyle = cx('gtmGoToHome ba b--alto br3 mb12 pointer mh4-l', style.OffersSizing);
  const OfferCards = map(offers, (offer: Offer) => {
    const { id, url } = offer;
    return (
      <LazyLoadImage
        alt={`Oferta ${Number(id) + 1} da loja ${storeName}`}
        src={url}
        effect="blur"
        wrapperClassName={offerStyle}
        className={style.imgObjectCover}
        title={`Oferta ${Number(id) + 1} da loja ${storeName}`}
        data-value={storeName}
        key={+id + 1}
      />
    );
  });
  return <>{OfferCards}</>;
};
