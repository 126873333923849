import React, { Component } from 'react';
import cx from 'classnames';
import { SpanRipple } from './components/SpanRipple';

interface Props {
  children: React.ReactNode;
  extendClassname?: any;
  rippleSlow?: boolean;
  dataValue?: string;
}

interface RippleState {
  x: number;
  y: number;
  id: number;
}

interface State {
  ripples: RippleState[];
}
const CIRCLE_SIZE = 16;

export class Ripple extends Component<Props, State> {
  state: State = {
    ripples: [],
  };
  id = 0;

  handleClick = (e: any) => {
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    const offsetX = Math.abs(CIRCLE_SIZE / 2 - x);
    const offsetY = Math.abs(CIRCLE_SIZE / 2 - y);
    const deltaX = CIRCLE_SIZE / 2 + offsetX;
    const deltaY = CIRCLE_SIZE / 2 + offsetY;
    const id = ++this.id;

    this.setState({
      ripples: [
        ...this.state.ripples,
        {
          id,
          x: deltaX,
          y: deltaY,
        },
      ],
    });

    setTimeout(() => {
      this.setState({
        ripples: this.shift(this.state.ripples),
      });
    }, 600);
  };

  shift(array: any[]) {
    const newArray = [...array];
    newArray.shift();
    return newArray;
  }

  renderRipples = () => {
    const { ripples } = this.state;
    const { rippleSlow } = this.props;

    return ripples.map((ripple: RippleState, i: number) => (
      <SpanRipple
        key={`${ripple.id}--${i}`}
        x={ripple.x}
        y={ripple.y}
        rippleSlow={rippleSlow}
        circleSize={CIRCLE_SIZE}
      />
    ));
  };

  render() {
    const { children, extendClassname } = this.props;
    const styleDiv = cx('gtmGoToStoreHome relative overflow-hidden mw6', extendClassname);
    return (
      <div className={styleDiv} onClick={this.handleClick} data-value={this.props.dataValue}>
        {children}
        {this.renderRipples()}
      </div>
    );
  }
}
